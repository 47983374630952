import React, { useState } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { getPageUrl } from "../locales/i18n";
import PATH from "../constants/cts_routes";
import logo from "../assets/images/logo-vichy.svg";
import {
  COLOR_BLACK,
  COLOR_HEADER_MENU_LINK_HOVER,
  COLOR_WHITE,
} from "../constants/cts_colors";
import { headerMenuLinks } from "../constants/cts_header";
import { IHeaderMenuLinks } from "../interfaces/headerMenu";

/**
 * This component is used to define and design the header elements such as Home, Login/Logout or many other internal links
 * It loops through a headerElements array object defined as  :
 * to render elements. Links rendered depend on user state (logged in or logged out)
 */

const Header = () => {
  const [showMenu, _setShowMenu] = useState<boolean>(false);
  
  return (
    <Wrapper>
      <Link to={getPageUrl(PATH.home)}>
        <Logo src={logo} alt="logo" />
      </Link>

      {/* toggle menu btn */}
      <ToggleMenuBtn
        className={`${showMenu ? "close-menu-btn" : "open-menu-btn"}`}
        onClick={() => _setShowMenu((lastVal) => !lastVal)}
      >
        <div className="toggle-menu-line line-1"></div>
        <div className="toggle-menu-line line-2"></div>
        <div className="toggle-menu-line line-3"></div>
      </ToggleMenuBtn>

      {/* menu */}
      <Menu className={showMenu ? "show" : ""}>
        <div className="overlay" />
        <div className="container">
          <MenuLinksContainer>
            <div className="links">
              {
                headerMenuLinks.map((link: IHeaderMenuLinks) => {
                  return (
                    <div key={"header-menu-link-" + headerMenuLinks.indexOf(link)}
                    >
                      <NavLink
                        to={getPageUrl(link.to)}
                        onClick={() => _setShowMenu(false)}
                        className={({ isActive }) => (isActive ? "active" : "") + " link"}
                      >
                        {link.name}
                      </NavLink>
                    </div>
                  )
                })
              }
            </div>
          </MenuLinksContainer>
        </div>
      </Menu>
    </Wrapper>
  );
};

export default Header;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  padding: 42px 24px 90px 24px;
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0));
`;

const Logo = styled.img`
  width: 100px;
`;

const ToggleMenuBtn = styled.div`
  position: fixed;
  top: 42px;
  right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
  gap: 5px;
  z-index: 1002;

  .toggle-menu-line {
    height: 2px;
    background-color: ${COLOR_WHITE};
    transition: transform 0.6s ease-out, width 0.6s ease-out, background-color 0.6s ease-out;
  }

  .line-1 {
    width: 100%;
  }

  .line-2 {
    width: 100%;
  }

  .line-3 {
    width: 100%;
  }

  &.close-menu-btn {
    .line-1 {
      transform: rotate(45deg) translate(5px, 5px);
      background-color: ${COLOR_BLACK};
    }

    .line-2 {
      width: 0%;
      background-color: ${COLOR_BLACK};
    }

    .line-3 {
      transform: rotate(-45deg) translate(5px, -5px);
      background-color: ${COLOR_BLACK};
    }
  }

  &.open-menu-btn {
    .line-1 {
      width: 100%;
    }

    .line-2 {
      width: 100%;
    }

    .line-3 {
      width: 100%;
    }
  }
`

const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 1001;
  transition: visibility .5s ease-out;

  .container {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${COLOR_WHITE};
    transition: top .5s ease-out;
    padding: 15px;
  }

  .overlay {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: background-color .5s ease-out, visibility .5s ease-out;
  }

  &.show {    
    visibility: visible;

    .container {
      top: 0;
    }

    .overlay {
      visibility: visible;
      background-color: rgba(0, 0, 0, 0.9);
    }
  }
`

const MenuLinksContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${COLOR_WHITE};

  .links {
    position: absolute;
    top: 0;
    left: 50%;
    width: 33.3%;
    height: 100%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${COLOR_WHITE};
    text-align: center;

    @media (max-width: 1024px) {
      width: 100%;
    }

    .link {
      color: ${COLOR_BLACK};
      font-size: 3rem;
      transition: color 0.2s ease-out;

      &:hover {
        color: ${COLOR_HEADER_MENU_LINK_HOVER};
      }

      @media (max-width: 1024px) {
        font-size: 2.5rem;
      }
    }
  }
`