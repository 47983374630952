import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useApp } from "../../../common/contexts/appContext";

let ON_DOWN = false;

const ScrollButton = () => {
  const requestAnimationRef = useRef();
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const { y } = useApp();

  const onMouveDown = () => {
    if (ON_DOWN) {
      window.scrollBy(0, 3);
    }

    // @ts-ignore
    requestAnimationRef.current = requestAnimationFrame(onMouveDown);
  };

  useEffect(() => {
    setRotation(y / window.innerHeight);

    let newScale = 1;
    newScale -= y / window.outerHeight;
    if (newScale < 0.5) {
      newScale = 0.5;
    }

    setScale(newScale);
  }, [y]);

  useEffect(() => {
    // @ts-ignore
    requestAnimationRef.current = requestAnimationFrame(onMouveDown);
    // @ts-ignore
    return () => cancelAnimationFrame(requestAnimationRef.current);
  }, []);

  return (
    <ButtonWrapper
      style={{ transform: `scale(${scale})`, visibility: scale === 0.5 ? 'hidden' : 'visible' }}
    >
      <ScrollDownBg style={{ transform: `rotate(${360 * rotation}deg)` }} />
      <span>
        SCROLL
        <br />
        DOWN
      </span>
    </ButtonWrapper>
  );
};

export default ScrollButton;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const ButtonWrapper = styled.div`
  position: relative;
  width: 114px;
  height: 114px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  text-align: center;

  span {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    font-size: 16px;
  }
`;

const ScrollDownBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(/images/scroll-down-circle.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;
