import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { MAIN_LEFT_RIGHT_MARGIN } from "../../../constants/cts_styles";
import { gsap } from "gsap";
//import videoAnimation from "../../../assets/videos/exposome-animation.json";
import LOGO from "../../../assets/images/exposome-logo.svg";
//import lottie from "lottie-web";

const HomeLogo = () => {
  const app = useRef<HTMLDivElement>(null);
  //const animationContainer = useRef<HTMLDivElement>(null);
  //const lottieRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      //var animDuration = 3000;
      /*const anim = lottie.loadAnimation({
        container: lottieRef.current!,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: videoAnimation,
      });*/

      /*function animatebodymovin(duration: number) {
        const scrollPosition = window.scrollY;
        const maxFrames = anim.totalFrames;
        const frame = (maxFrames / 100) * (scrollPosition / (duration / 100));
        anim.goToAndStop(frame, true);
      }*/

      /*const onScroll = () => {
        animatebodymovin(animDuration);
      };*/

      setTimeout(() => {
        gsap.fromTo(
          ".home-logo-wrapper > div",
          { opacity: 0 },
          {
            opacity: 1,
            scrollTrigger: {
              trigger: ".home-logo-wrapper > div",
              start: "top bottom",
              end: "bottom bottom",
              scrub: 1,
            },
          }
        );

        const imagePinsComin = {
          trigger: ".home-logo-wrapper > div",
          start: "top 100%",
          end: "top 75%",
          scrub: 1,
        };

        gsap.set(".home-logo-wrapper img.logo-exposome", { // .home-logo-wrapper div.video-logo-animation
          right: window.innerWidth * -2 + "px",
        });
        gsap.to(".home-logo-wrapper img.logo-exposome", { // .home-logo-wrapper div.video-logo-animation
          right: 0,
          ease: "power4.inout",
          scrollTrigger: {
            ...imagePinsComin,
            start: "top 80%",
            end: "top 40%",
          },
        });

        gsap.set(".home-logo-wrapper img.logo-exposome", { // .home-logo-wrapper div.video-logo-animation
          rotationY: 180,
        });
        gsap.to(".home-logo-wrapper img.logo-exposome", { // .home-logo-wrapper div.video-logo-animation
          rotationY: 0,
          ease: "power0",
          scrollTrigger: {
            ...imagePinsComin,
            start: "top 80%",
            end: "top 40%",
          },
        });

        gsap.set(".home-logo-wrapper img.logo-exposome", { // .home-logo-wrapper div.video-logo-animation
          top: window.innerHeight * -0.75 + "px",
        });
        gsap.to(".home-logo-wrapper img.logo-exposome", { // .home-logo-wrapper div.video-logo-animation
          top: 0,
          ease: "power1",
          scrollTrigger: {
            ...imagePinsComin,
            start: "top 80%",
            end: "top 40%",
          },
        });

        gsap.fromTo(
          ".home-logo-wrapper img.logo-exposome", // .home-logo-wrapper div.video-logo-animation
          { scale: 0 },
          {
            scale: 1,
            ease: "power1.out",
            scrollTrigger: {
              ...imagePinsComin,
              start: "top 80%",
              end: "top 25%",
            },
          }
        );

        gsap.fromTo(
          ".home-logo-wrapper img.logo-exposome", // .home-logo-wrapper div.video-logo-animation
          { opacity: 0 },
          {
            opacity: 1,
            ease: "power1.out",
            scrollTrigger: {
              ...imagePinsComin,
              start: "top 100%",
              end: "top 0%",
            },
          }
        );

        gsap.to(".home-logo-wrapper", {
          scrollTrigger: {
            trigger: app.current,
            start: "top top",
            end: "bottom 50%",
            pin: ".home-logo-wrapper > div",
          },
        });

        // words
        gsap.fromTo(
          ".home-logo-wrapper .levitation",
          { scale: 0, opacity: 0 },
          {
            scale: 1,
            opacity: 0.4,
            transformOrigin: "50% 50%",
            ease: "power4.out",
            scrollTrigger: {
              ...imagePinsComin,
              start: "top 75%",
              end: "top 25%",
            },
          }
        );

        // go on
        const imagePinsGoOn = {
          trigger: "#environmental-factors",
          start: "top 50%",
          scrub: 1,
        };

        gsap.to(".home-logo-wrapper .block-images", {
          left: window.innerWidth * -2 + "px",
          ease: "power4.out",
          scrollTrigger: {
            ...imagePinsGoOn,
            end: "25% 50%",
          },
        });

        gsap.set(".block-images", { rotationY: 0 });
        gsap.to(".block-images", {
          rotationY: -180,
          ease: "power4",
          scrollTrigger: {
            ...imagePinsGoOn,
            end: "25% 50%",
          },
        });

        gsap.to(".home-logo-wrapper .block-images", {
          opacity: 0,
          ease: "power1.out",
          scrollTrigger: {
            ...imagePinsGoOn,
            end: "top 25%",
          },
        });

        gsap.to(".home-logo-wrapper .block-images", {
          scale: 6,
          ease: "power1.out",
          scrollTrigger: {
            ...imagePinsGoOn,
            end: "25% 100%",
          },
        });

        gsap.to(".home-logo-wrapper .block-images", {
          top: window.innerHeight * 2 + "px",
          ease: 'CustomEase.create("custom", "M0,0,C0.266,0.412,0.693,0.421,0.822,0.542,0.866,0.583,0.78,1,1,1")',
          scrollTrigger: {
            ...imagePinsGoOn,
            end: "25% 50%",
          },
        });

        // words
        gsap.to(".home-logo-wrapper .levitation-block", {
          opacity: 0,
          transformOrigin: "50% 50%",
          ease: "power4.out",
          scrollTrigger: {
            ...imagePinsGoOn,
            end: "25% 50%",
          },
        });
      }, 100);

      //document.addEventListener("scroll", onScroll);

      return () => {
        //anim.destroy();
        //document.removeEventListener("scroll", onScroll);
      };
    }, app);

    return () => ctx.revert();
  }, []);

  return (
    <HomeLogoWrapper ref={app} className="home-logo-wrapper">
      <div>
        <div className="physical levitation-block">
          <p className="levitation">
            PHYSICAL
            <br /> & EMOTIONAL IMPLICATIONS
          </p>
        </div>
        <div className="skin levitation-block">
          <p className="levitation">SKIN</p>
        </div>
        <div className="scalp levitation-block">
          <p className="levitation">SCALP</p>
        </div>
        <div className="various levitation-block">
          <p className="levitation">
            VARIOUS
            <br />
            LIFE STAGES
          </p>
        </div>
        <div className="block-images">
          {/*<div ref={lottieRef} className="video-logo-animation"
          ></div>*/}
          <img src={LOGO} className="logo-exposome" />
        </div>
      </div>
    </HomeLogoWrapper>
  );
};

export default HomeLogo;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const HomeLogoWrapper = styled.div`
  height: 100vh;
  padding: 0 ${MAIN_LEFT_RIGHT_MARGIN}px;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    position: relative;

    > p {
      position: relative;
    }

    .block-images {
      position: relative;
    }

    .video-logo-animation {
      position: relative;
      width: 300px;
    }

    .logo-exposome {
      position: relative;
      width: 300px;
    }

    .levitation-block {
      font-size: 32px;
      position: absolute;
      color: white;
      font-weight: bolder;
      text-align: center;
      opacity: 0.4;
      line-height: 40px;

      &.physical {
        top: -150px;
        width: 220px;
        right: -10px;

        @media (min-height: 667px) {
          top: -180px;
        }
        @media (min-width: 700px) {
          right: -100px;
        }
      }

      &.skin {
        left: 0;
        top: 0;

        @media (min-height: 667px) {
          top: -50px;
        }
        @media (min-width: 700px) {
          left: -50px;
        }
      }

      &.scalp {
        right: 0;
        bottom: -50px;

        @media (min-width: 700px) {
          right: -50px;
        }
      }

      &.various {
        left: 0;
        bottom: -130px;
        width: 170px;

        @media (min-width: 700px) {
          left: -50px;
        }
      }
    }
  }
`;

const SvgElement = styled.div`
  width: 100%;
  position: relative;
  height: 250px;
`;
