import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MAIN_LEFT_RIGHT_MARGIN } from "../../../constants/cts_styles";
import { gsap } from "gsap";
import BubblePresentation, {
  BubblePresentationInterface,
} from "../components/BubblePresentation";
import { useApp } from "../../../common/contexts/appContext";

const LifeStyleFactors = () => {
  const app = useRef<HTMLDivElement>(null);
  const titleApp = useRef<HTMLDivElement>(null);
  const titleFixedApp = useRef<HTMLDivElement>(null);
  const [blockHeader, setBlockHeader] = useState(false);
  const { y } = useApp();
  const factorsElements: BubblePresentationInterface[] = [
    {
      key: "2-1",
      iconPath: "/images/circles/lack-of-sleep.svg",
      circle: "top-white",
      top: {
        title: "SKIN",
        htmlDescription:
          "Increase in trans-epidermal water loss and disruption & of collagen formation increasing visible signs of aging.",
        showLine: true,
      },
      bottom: {
        title: "SCALP",
        htmlDescription: "lack of rest affects the hair's cell cyde.",
        showLine: true,
      },
    },
    {
      key: "2-2",
      iconPath: "/images/circles/nutrition.svg",
      circle: "top-white",
      bottom: {
        title: "SKIN",
        htmlDescription:
          "Consuming too much sugar causes wrinkles.<br/>A diet rich in antioxidants may delay aging effects.<br/>Vitamin C intake was associated with a lower likelihood of wrinkled appearance higher fat and carbohydrate intake were associated with higher likelihood of wrinkled appearance.",
        showLine: true,
      },
    },
    {
      key: "2-3",
      iconPath: "/images/circles/stress.svg",
      circle: "top-white",
      top: {
        title: "SKIN",
        htmlDescription:
          "Decline of epidermal permeability and deterioration in skin barrier tunction.",
        showLine: true,
      },
      bottom: {
        title: "SCALP",
        htmlDescription:
          "<b>56</b>% of seborrheic dermatitis cases due to the increase of cortisol levels.",
        showLine: true,
      },
    },
    {
      key: "2-4",
      iconPath: "/images/circles/tabacco.svg",
      circle: "top-white",
      bottom: {
        title: "SKIN",
        htmlDescription:
          "In a twin study, participant who smoked for 10 years appeared 2.5 years older than their non-smoking twins.",
        showLine: true,
      },
    },
  ];

  useEffect(() => {
    if (titleApp) {
      // @ts-ignore
      // const { top } = titleApp.current?.getBoundingClientRect();

      // setBlockHeader(top < 100);
    }
  }, [y]);

  useEffect(() => {
    let ctx = gsap.context(() => {
      setTimeout(() => {
        gsap.to(titleApp.current, {
          scale: 0.75,
          opacity: 0.4,
          scrollTrigger: {
            trigger: titleApp.current,
            scrub: 1,
            start: "top 30%",
          },
        });

        gsap.to(titleFixedApp.current, {
          marginTop: -40,
          opacity: 0,
          scrollTrigger: {
            trigger: "#hormonal-factors",
            scrub: 1,
            start: "top 25%",
            end: "top top",
          },
        });
      }, 500);
    }, app);

    return () => ctx.revert();
  }, []);

  return (
    <LifeStyleFactorsWrapper ref={app} id="life-style-factors">
      <TitleFactore className="title-env">
        <div>
          <h2
            ref={titleApp}
            style={{ visibility: blockHeader ? "hidden" : "visible" }}
          >
            <b>LIFESTYLE</b>
            <br />
            FACTORS
          </h2>
          <div className="nutrition levitation-block">
            <p className="levitation">NUTRITION</p>
          </div>
          <div className="tabacco levitation-block">
            <p className="levitation">TABACCO</p>
          </div>
          <div className="stress levitation-block">
            <p className="levitation">STRESS</p>
          </div>
          <div className="lack levitation-block">
            <p className="levitation">LACK OF SLEEP</p>
          </div>
          <div className="diet levitation-block">
            <p className="levitation">DIET</p>
          </div>
          <div className="alcohol levitation-block">
            <p className="levitation">ALCOHOL</p>
          </div>
        </div>
      </TitleFactore>
      <TitleFixedFactore
        ref={titleFixedApp}
        style={{ visibility: !blockHeader ? "hidden" : "visible" }}
      >
        <b>LIFESTYLE</b>
        <br />
        FACTORS
      </TitleFixedFactore>
      {factorsElements.map((f, index) => (
        <div key={index}>
          <BubblePresentation item={f} />
        </div>
      ))}
    </LifeStyleFactorsWrapper>
  );
};

export default LifeStyleFactors;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const LifeStyleFactorsWrapper = styled.div`
  position: relative;
`;

const TitleFactore = styled.div`
  padding: 20vh ${MAIN_LEFT_RIGHT_MARGIN}px 0 ${MAIN_LEFT_RIGHT_MARGIN}px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    position: relative;
  }

  h2 {
    text-align: center;
    font-size: 64px;
    font-weight: 300;

    @media (max-height: 667px), (max-width: 450px) {
      font-size: 32px;
    }

    @media (min-width: 700px) {
      width: 400px;
    }
  }

  .levitation-block {
    font-size: 32px;
    position: absolute;
    color: white;
    font-weight: bolder;
    text-align: center;
    opacity: 0.4;
    white-space: nowrap;

    > p {
      position: relative;
    }

    &.nutrition {
      right: -69px;
      top: -250px;
    }

    &.tabacco {
      left: -69px;
      top: -180px;
    }

    &.stress {
      right: -59px;
      top: -100px;
    }

    &.lack {
      left: -75px;
      bottom: -80px;
    }

    &.diet {
      right: -59px;
      bottom: -150px;
    }

    &.alcohol {
      left: -59px;
      bottom: -200px;
    }
  }
`;

const TitleFixedFactore = styled.h2`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: scale(0.8);
  opacity: 0.4;
  text-align: center;
  font-size: 32px;
  margin-top: 90px;
  font-weight: 300;
`;
