import React from 'react';
import styled from 'styled-components';
import backgroundImg from '../../../assets/images/routes/quiz/background-image.jpg';

const BackgroundImage = () => {
  return (
    <Wrapper>
      <img src={backgroundImg} alt='background' />
    </Wrapper>
  );
};

export default BackgroundImage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  img {
    width: auto;
    height: 100%;
  }
`;
