import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Button = ({
  children,
  onClick,
  to,
}: {
  children: any;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  to?: string;
}) => {
  if (to) {
    return (
      <Link to={to}>
        <ButtonStyles onClick={onClick}>{children}</ButtonStyles>
      </Link>
    );
  }

  return <ButtonStyles onClick={onClick}>{children}</ButtonStyles>;
};

export default Button;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const ButtonStyles = styled.button`
  padding: 16px 32px;
  font-size: 16px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  background: white;
  color: black;
`;
