import React, { createContext, useCallback, useState } from "react";
import { IQuizContext } from "../../interfaces/quiz";
import { onRegisterQuizAnswersApi } from "../../api/quiz.api";


const QuizContext = createContext(null);

export function QuizProvider(props : any) {
  const [isLoadingQuiz, _setIsLoadingQuiz] = useState(false);

  // REGISTER ANSWERS
  const onRegisterQuizAnswers = useCallback(async ({userId, formAnswers}: {userId: string, formAnswers: Array<{}>}) => {
    _setIsLoadingQuiz(true);
    return  onRegisterQuizAnswersApi({userId, formAnswers})
              .then((response: any) => {
                _setIsLoadingQuiz(false)
                return (response)
              })
              .catch((error: any) => {
                if (error.response) {
                  throw new Error(error.response.data);
                } else {
                  throw new Error(error.message);
                }
              })
              .then((response: any) => {
                _setIsLoadingQuiz(false)
                return (response)
              });
  }, []);

  return (
    <QuizContext.Provider
      {...props}
      value={{
        isLoadingQuiz,
        // function
        onRegisterQuizAnswers,
      }}
    />
  );
}

export const useQuiz = () : IQuizContext => {
  const context = React.useContext(QuizContext);
  if (!context)
    throw new Error(
      "useQuiz must be used in QuizProvider"
    );

  return context;
};
