import React from "react";
import styled from "styled-components";
import { usePlayer } from "../../../common/contexts/playerContext";
import SongPlay from "../../../assets/images/song-play.svg";
import SongStop from "../../../assets/images/song-stop.svg";

const BackgroundAudio = () => {
  const { togglePlay, isPlay } = usePlayer();

  return (
    <ButtonWrapper
      onMouseDown={togglePlay}
      onMouseUp={togglePlay}
      onTouchStart={togglePlay}
    >
      <img src={!isPlay ? SongStop : SongPlay} />
    </ButtonWrapper>
  );
};

export default BackgroundAudio;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const ButtonWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: 36px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  img {
    height: 18px;
  }
`;
