import { quizFormResultResponses } from "../constants/cts_quiz";
import { IQuizResult } from "../interfaces/quiz";

export const getQuizResult = ({correctAnswersNb, questionsNb}: {correctAnswersNb: number, questionsNb: number}) => {
  const resultPercentage = 100 * correctAnswersNb / questionsNb;
  const finalResults: Array<IQuizResult> = [];

  // for each result
  quizFormResultResponses.map((response) => {
    if (resultPercentage >= response.minPercentage) {
      return finalResults.push({correctAnswersNb, questionsNb, percentage: resultPercentage, response});
    }
  })

  // select THE LAST result from the list
  return finalResults[finalResults.length - 1];
}