import React from "react";
import styled from "styled-components";
import ScrollButton from "./ScrollButton";
import BackgroundAudio from "../backgrounds/BackgroundAudio";

const BottomActions = () => {
  return (
    <BottomActionsWrapper>
      <ScrollButton />
      <BackgroundAudio />
    </BottomActionsWrapper>
  );
};

export default BottomActions;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const BottomActionsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 162px;
  display: flex;
  justify-content: center;
  background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));

  @media (max-height: 667px), (max-width: 450px) {
    height: 120px;
  }
`;