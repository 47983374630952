const QUESTION_WHAT_IS_EXPOSOME = 'What is exposome for you?';
const QUESTION_WHAT_IS_EXPOSOME_CORRECT_ANSWER = 'External and internal exposures a person may encounter throughout their lifetime';

const QUESTION_EXPOSOME_FACTORS = 'From the below select which ones are the exposome factors?';
const QUESTION_EXPOSOME_FACTORS_CORRECT_ANSWER = 'All of the above';

const QUESTION_VICHY_LABORATOIRES_STUDY_TIME = 'How long has vichy laboratoires been studying the impact of menopause and hormones on skin?';
const QUESTION_VICHY_LABORATOIRES_STUDY_TIME_CORRECT_ANSWER = '+20 years';

const QUESTION_SKIN_ISSUES = 'What are the most prevalent skin issues caused by NO2 pollution?';
const QUESTION_SKIN_ISSUES_CORRECT_ANSWER = 'Pigment spots';

const QUESTION_STRESS_INTENSIFIES_SEBORRHEIC_DERMATITIES_CASES_BY = 'Stress intensifies seborrheic dermatitis cases by:';
const QUESTION_STRESS_INTENSIFIES_SEBORRHEIC_DERMATITIES_CASES_BY_CORRECT_ANSWER = '56%';

// QUIZ FORM QUESTIONS
export const quizFormQuestions = [
  {
    title: QUESTION_WHAT_IS_EXPOSOME,
    correctAnswer: QUESTION_WHAT_IS_EXPOSOME_CORRECT_ANSWER,
    answers: [
      {
        title: 'The external factors to which we are exposed in specific times of our daily lives',
        isSelected: true,
      },
      {
        title: QUESTION_WHAT_IS_EXPOSOME_CORRECT_ANSWER,
        isSelected: false,
      },
    ],
  },
  {
    title: QUESTION_EXPOSOME_FACTORS,
    correctAnswer: QUESTION_EXPOSOME_FACTORS_CORRECT_ANSWER,
    answers: [
      {
        title: 'Environmental',
        isSelected: true,
      },
      {
        title: 'Lifestyle',
        isSelected: false,
      },
      {
        title: 'Hormonal',
        isSelected: false,
      },
      {
        title: QUESTION_EXPOSOME_FACTORS_CORRECT_ANSWER,
        isSelected: false,
      },
      {
        title: 'Noone of the above',
        isSelected: false,
      }
    ],
  },
  {
    title: QUESTION_VICHY_LABORATOIRES_STUDY_TIME,
    correctAnswer: QUESTION_VICHY_LABORATOIRES_STUDY_TIME_CORRECT_ANSWER,
    answers: [
      {
        title: '4 years',
        isSelected: true,
      },
      {
        title: '+10 years',
        isSelected: false,
      },
      {
        title: QUESTION_VICHY_LABORATOIRES_STUDY_TIME_CORRECT_ANSWER,
        isSelected: false,
      },
    ],
  },
  {
    title: QUESTION_SKIN_ISSUES,
    correctAnswer: QUESTION_SKIN_ISSUES_CORRECT_ANSWER,
    answers: [
      {
        title: 'Atopic dermatitis',
        isSelected: true,
      },
      {
        title: QUESTION_SKIN_ISSUES_CORRECT_ANSWER,
        isSelected: false,
      },
      {
        title: 'Acne',
        isSelected: false,
      },
    ],
  },
  {
    title: QUESTION_STRESS_INTENSIFIES_SEBORRHEIC_DERMATITIES_CASES_BY,
    correctAnswer: QUESTION_STRESS_INTENSIFIES_SEBORRHEIC_DERMATITIES_CASES_BY_CORRECT_ANSWER,
    answers: [
      {
        title: '17%',
        isSelected: true,
      },
      {
        title: '27%',
        isSelected: false,
      },
      {
        title: QUESTION_STRESS_INTENSIFIES_SEBORRHEIC_DERMATITIES_CASES_BY_CORRECT_ANSWER,
        isSelected: false,
      },
      {
        title: '63%',
        isSelected: false,
      },
    ],
  },
]

// QUIZ FORM RESULTS 
export const quizFormResultResponses = [
  {
    minPercentage: 0,
    text: 'Not quite right, however we have good news! Today you will be discovering a lot of exciting data on exposome science and become an exposome master!',
  },
  {
    minPercentage: 40,
    text: 'Great job! You are about to become an exposome master after the immersive exposome box experience!',
  },
  {
    minPercentage: 81,
    text: 'Congratulations! You are one of our unique exposome masters! Now your turn to experience it!',
  },
]