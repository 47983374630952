// Cet object contient tout les chemins nécéssaire à l'application
// Lors de la création d'une nouvelle page pour l'application, 
// il faut ajouter ici le chemin vers celle-ci associé à un nom (clé)

const PATH = {
  home : '',
  quiz: 'quiz',
  quizResult: 'quiz/result',
  legalMentions : 'legal-mentions',
  contact : 'contact',
  about :  'about',
}

export default PATH;