import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";

export interface BubblePresentationInterface {
  key: string;
  iconPath?: string;
  circle: string;
  top?: {
    title?: string;
    htmlDescription?: string;
    showLine?: boolean;
  };
  bottom?: {
    title?: string;
    htmlDescription?: string;
    showLine?: boolean;
  };
}

const BubblePresentation = ({
  item,
}: {
  item: BubblePresentationInterface;
}) => {
  const app = useRef<HTMLDivElement>(null);

  let getBGSprint = "";
  switch (item.circle) {
    case "bottom-left":
      getBGSprint = "/images/cercle-left-white.svg";
      break;
    case "bottom-right":
      getBGSprint = "/images/cercle-right-white.svg";
      break;
    default:
      getBGSprint = "/images/cercle-top-white.svg";
      break;
  }

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {}, app);

    return () => ctx.revert();
  }, []);

  return (
    <BubblePresentationWrapper ref={app} className="bubble">
      <div className="spin-wrapper-out">
        <Spins
          className="spin"
          style={{ backgroundImage: `url(${getBGSprint}` }}
        >
          <div
            className="background"
            style={{ backgroundImage: `url(${item.iconPath}` }}
          />
          {item.top && (
            <TopPanel
              bottomLine={item.bottom?.showLine ? true : false}
              removeMargin={item.top?.showLine ? false : true}
              className="top-panel"
            >
              {item.top.showLine && <VLineTop />}
              {item.top.title && <TagButton>{item.top.title}</TagButton>}
              {item.top.htmlDescription && (
                <TagDescription
                  dangerouslySetInnerHTML={{ __html: item.top.htmlDescription }}
                ></TagDescription>
              )}
            </TopPanel>
          )}
          {item.bottom && (
            <BottomPanel
              topLine={item.top?.showLine ? true : false}
              removeMargin={item.bottom?.showLine ? false : true}
              className="bottom-panel"
            >
              {item.bottom.showLine && <VLineBottom />}
              {item.bottom.title && <TagButton>{item.bottom.title}</TagButton>}
              {item.bottom.htmlDescription && (
                <TagDescription
                  dangerouslySetInnerHTML={{
                    __html: item.bottom.htmlDescription,
                  }}
                ></TagDescription>
              )}
            </BottomPanel>
          )}
        </Spins>
      </div>
    </BubblePresentationWrapper>
  );
};

export default BubblePresentation;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const BubblePresentationWrapper = styled.div`
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .spin-wrapper-out {
    position: relative;
  }
`;

const Spins = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-height: 667px), (max-width: 450px) {
    width: 100px;
    height: 100px;

    &:before {
      height: 30px;
    }

    &:after {
      height: 30px;
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

interface PanelProps {
  removeMargin: boolean;
  topLine?: boolean;
  bottomLine?: boolean;
}

const TopPanel = styled.div<PanelProps>`
  position: absolute;
  left: -50%;
  right: 0;
  bottom: 100%;
  margin-bottom: ${(props) => (props.removeMargin ? "18px" : "64px")};
  width: 300%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:before {
    content: ${(props) => (props.bottomLine ? '" "' : "")};
    position: absolute;
    top: 100%;
    margin-top: 8px;
    height: 50px;
    width: 2px;
    background-color: white;
  }

  @media (max-height: 667px), (max-width: 450px) {
    left: -100%;

    &:before {
      height: 30px;
    }
  }

  @media (min-width: 600px) {
    max-width: 400px;
    transform: translateX(-50px);
  }

  @media (min-width: 800px) {
    max-width: 600px;
    transform: translateX(-75px);
  }
`;

const BottomPanel = styled.div<PanelProps>`
  position: absolute;
  left: -50%;
  right: 0;
  top: 100%;
  margin-top: ${(props) => (props.removeMargin ? "18px" : "64px")};
  width: 300%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:after {
    content: ${(props) => (props.topLine ? '" "' : "")};
    position: absolute;
    bottom: 100%;
    margin-bottom: 8px;
    height: 50px;
    width: 2px;
    background-color: white;
  }

  @media (max-height: 667px), (max-width: 450px) {
    left: -100%;

    &:after {
      height: 30px;
    }
  }

  @media (min-width: 600px) {
    max-width: 400px;
    transform: translateX(-50px);
  }

  @media (min-width: 800px) {
    max-width: 600px;
    transform: translateX(-75px);
  }
`;

const TagButton = styled.div`
  background-color: white;
  font-size: 12px;
  width: 50px;
  padding: 0 2px;
  margin-bottom: 8px;
  color: #878787;
  text-align: center;
`;

const TagDescription = styled.p`
  text-align: center;
  font-size: 18px;
`;

const VLineTop = styled.div`
  position: absolute;
  bottom: -58px;
  height: 50px;
  width: 2px;
  background-color: white;
`;

const VLineBottom = styled.div`
  position: absolute;
  top: -58px;
  height: 50px;
  width: 2px;
  background-color: white;
`;
