import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { MAIN_LEFT_RIGHT_MARGIN } from "../../../constants/cts_styles";
import { gsap } from "gsap";

const HomeCover = () => {
  const app = useRef<HTMLDivElement>(null);

  const welcomeText = "WELCOME TO THE IMMERSIVE EXPOSOME EXPERIENCE";
  const boldTexts = [4];

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to(".cover-app span", {
        opacity: 0,
        duration: 1,
        ease: "power4.out",
        stagger: 0.5,
        scrollTrigger: {
          start: "top top",
          end: "bottom 40%",
          scrub: 0.5,
        },
      });

      gsap.to(app.current, {
        opacity: 0,
        scrollTrigger: {
          start: "top top",
          end: "bottom 50%",
          scrub: 1,
        },
      });
    }, app);

    return () => ctx.revert();
  }, []);

  return (
    <HomeCoverWrapper ref={app}>
      <VLogo>
        <div />
      </VLogo>
      <div className="home-cover-content">
        <h1 className="cover-app">
          {welcomeText.split(" ").map((t, index) => (
            <React.Fragment key={index}>
              <span className={boldTexts.indexOf(index) !== -1 ? "bold" : ""}>
                {t}{" "}
              </span>
              {t === "TO" ? <br /> : ""}
            </React.Fragment>
          ))}
        </h1>
      </div>
    </HomeCoverWrapper>
  );
};

export default HomeCover;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const HomeCoverWrapper = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .home-cover-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-size: 40px;
    line-height: 42px;
    color: white;
    text-align: center;
    font-weight: 300;
    margin-left: ${MAIN_LEFT_RIGHT_MARGIN}px;
    margin-right: ${MAIN_LEFT_RIGHT_MARGIN}px;
    max-width: 500px;

    span {
      display: inline-block;
      margin-right: 8px;
    }

    span.bold {
      font-weight: bolder;
      font-size: 63px;
      line-height: 50px;
    }
  }

  button {
    margin-top: 43px;
    max-width: 75%;
  }
`;

const VLogo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 90%;
    max-width: 500px;
    height: 100%;
    opacity: 0.15;
    margin: auto;
    background-image: url(/images/v-vichy.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;
