import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  BACKGROUND_VIDEO_AMBIENCE_MP4,
  BACKGROUND_VIDEO_ENVIRONMENTAL_MP4,
  BACKGROUND_VIDEO_LIFE_STYLE_MP4,
  BACKGROUND_VIDEO_HORMONAL_MP4,
} from "../../../constants/cts_eternal_assets";
import { gsap } from "gsap";

const BackgroundVideo = () => {
  const app = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      setTimeout(() => {
        gsap.to(".environmental-video", {
          opacity: 1,
          duration: 0.5,
          ease: "power4.out",
          scrollTrigger: {
            trigger: "#environmental-factors",
            start: "top-=200 top",
            end: "bottom bottom",
            scrub: 1,
          },
        });
        gsap.to(".life-style-video", {
          opacity: 1,
          duration: 0.5,
          ease: "power4.out",
          scrollTrigger: {
            trigger: "#life-style-factors",
            start: "top-=200 top",
            end: "bottom bottom",
            scrub: 1,
          },
        });
        gsap.to(".hormonal-video", {
          opacity: 1,
          duration: 0.5,
          ease: "power4.out",
          scrollTrigger: {
            trigger: "#hormonal-factors",
            start: "top-=200 top",
            end: "bottom bottom",
            scrub: 1,
          },
        });
      }, 500);
    }, app);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={app}>
      <BackgroundVideoWrapper>
        <video autoPlay loop muted playsInline>
          <source src={BACKGROUND_VIDEO_AMBIENCE_MP4} type="video/mp4" />
        </video>
      </BackgroundVideoWrapper>
      <BackgroundVideoWrapper className="environmental-video">
        <video autoPlay loop muted playsInline>
          <source src={BACKGROUND_VIDEO_ENVIRONMENTAL_MP4} type="video/mp4" />
        </video>
      </BackgroundVideoWrapper>
      <BackgroundVideoWrapper className="life-style-video">
        <video autoPlay loop muted playsInline>
          <source src={BACKGROUND_VIDEO_LIFE_STYLE_MP4} type="video/mp4" />
        </video>
      </BackgroundVideoWrapper>
      <BackgroundVideoWrapper className="hormonal-video">
        <video autoPlay loop muted playsInline>
          <source src={BACKGROUND_VIDEO_HORMONAL_MP4} type="video/mp4" />
        </video>
      </BackgroundVideoWrapper>
    </div>
  );
};

export default BackgroundVideo;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const BackgroundVideoWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  &.environmental-video, &.life-style-video, &.hormonal-video {
    opacity: 0;
  }

  @media (min-aspect-ratio: 16/9) {
    video {
      width: 100%;
      height: auto;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    video {
      width: auto;
      height: 100%;
    }
  }
`;
