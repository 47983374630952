import React from "react";
import styled from "styled-components";
import BackgroundImage from "./backgrounds/BackgroundImage";
import { COLOR_WHITE } from "../../constants/cts_colors";

const LegalMentions = () => {
  return (
    <Wrapper>
      <BackgroundImage />
      <Content>
        <h1 className="title">Legal Mentions</h1>
        <div className='text' />
      </Content>
    </Wrapper>
  )
}

export default LegalMentions;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

const Content = styled.form`
  position: absolute;
  top: 170px;
  left: 24px;
  right: 24px;
  height: calc(100% - 240px);
  overflow-y: scroll;
  padding: 0px 26px;
  z-index: 1;

  /* scrollbar */
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255 ,255 ,255, 0.12);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255 ,255 ,255, 0.35);
    border-radius: 10px;

    &:hover {
      background: rgba(255 ,255 ,255, 0.50);
    }
  }

  .title {
    color: ${COLOR_WHITE};
    text-align: center;
  }

  .text {
    color: ${COLOR_WHITE};
  }
`
