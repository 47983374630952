import React, { ReactNode } from "react";
import styled from "styled-components"
import Header from "./Header";
import * as PropTypes from 'prop-types'

/**
 * This component is used to encompass the Header, Footer and given children together
 */

const WrapperConnected = ({children } : 
  {children: ReactNode }) => {

  return (
    <Wrapper>
      <Header/>
      <Container>
        {children}
      </Container>
    </Wrapper>
  );
};

export default WrapperConnected;

WrapperConnected.propTypes = {
  /** Children to render*/
  children: PropTypes.any.isRequired,
}

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
`

const Container = styled.div`
`