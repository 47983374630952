import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MAIN_LEFT_RIGHT_MARGIN } from "../../../constants/cts_styles";
import { gsap } from "gsap";
import BubblePresentation, {
  BubblePresentationInterface,
} from "../components/BubblePresentation";
import { useApp } from "../../../common/contexts/appContext";
import NETLOCK_IMG from "../../../assets/images/netlock.svg";
import { CustomEase } from "gsap/all";

const EnvironmentalFactors = () => {
  const app = useRef<HTMLDivElement>(null);
  const titleApp = useRef<HTMLDivElement>(null);
  const titleFixedApp = useRef<HTMLDivElement>(null);
  const [blockHeader, setBlockHeader] = useState(false);
  const { y } = useApp();
  const factorsElements: BubblePresentationInterface[] = [
    {
      key: "1-1",
      iconPath: "/images/circles/uvs.svg",
      circle: "top-white",
      top: {
        title: "SKIN",
        htmlDescription: "UV rays contribute to <b>80</b>% of aging signs.",
        showLine: true,
      },
      bottom: {
        title: "SCALP",
        htmlDescription:
          "UV radiation degrades the internal protein structures and weakens the protective layers resulting in hair or pigment loss.",
        showLine: true,
      },
    },
    {
      key: "1-2",
      iconPath: "/images/circles/pollution.svg",
      circle: "top-white",
      bottom: {
        title: "SKIN",
        htmlDescription:
          "Increase in NO2 was associated with <b>25%</b> more pigment spots on the cheeks of German women, and <b>24</b>% in Chinese women.",
        showLine: true,
      },
    },
    {
      key: "1-3",
      iconPath: "/images/circles/climate-changes.svg",
      circle: "top-white",
      top: {
        title: "SKIN",
        htmlDescription:
          "Enhance the impact of solar rays resulting in accelerated photoaging.",
        showLine: true,
      },
      bottom: {
        title: "SCALP",
        htmlDescription:
          "Hot climate, aggravated <b>35</b>% of seborrheic dermatitis cases in the Thai population.",
        showLine: true,
      },
    },
  ];

  useEffect(() => {
    if (titleApp) {
      // @ts-ignore
      //const { top } = titleApp.current?.getBoundingClientRect();

      //setBlockHeader(top < 100);
    }
  }, [y]);

  useEffect(() => {
    let ctx = gsap.context(() => {
      setTimeout(() => {
        /** INIT TEXT WITH SPINS */
        gsap.set(".top-panel, .bottom-panel", {
          opacity: 0,
        });

        /** PINS */
        gsap.utils.toArray(".bubble").forEach((elem, index) => {
          const currentBubble = elem as HTMLElement;
          const triggerConfig = {
            scrollTrigger: {
              trigger: currentBubble,
              start: "35% 40%",
              end: "40% 0%",
              scrub: 1,
            },
          };
          gsap.to(currentBubble, {
            scrollTrigger: {
              ...triggerConfig.scrollTrigger,
              pin: currentBubble,
            },
          });

          /** TEXT WITH SPINS */
          const topPan = currentBubble.getElementsByClassName("top-panel")[0];
          const bottomPan =
            currentBubble.getElementsByClassName("bottom-panel")[0];
          if (topPan) gsap.to(topPan, { opacity: 1, ...triggerConfig });
          if (bottomPan) gsap.to(bottomPan, { opacity: 1, ...triggerConfig });
        });

        /** IN */
        gsap.utils.toArray(".spin").forEach((elem) => {
          const currentSpin = elem as HTMLElement;
          const triggerConfig = {
            scrollTrigger: {
              trigger: currentSpin,
              start: "top 50%",
              end: "top+=230px top",
              scrub: 1,
            },
          };
          gsap.set(currentSpin, {
            opacity: 0,
            scale: 0,
            right: window.innerWidth * -2 + "px",
            rotationY: 180,
            top: window.innerHeight * -0.75 + "px",
          });
          gsap.to(currentSpin, {
            top: 0,
            ease: "power1.out",
            ...triggerConfig,
          });
          gsap.to(currentSpin, {
            opacity: 1,
            ease: "power2.in",
            ...triggerConfig,
          });
          gsap.to(currentSpin, { scale: 1, ...triggerConfig });
          gsap.to(currentSpin, {
            right: 0,
            ease: "power4.inout",
            ...triggerConfig,
          });
          gsap.to(currentSpin, {
            rotationY: 0,
            ease: "power0",
            ...triggerConfig,
          });
        });

        /** OUT */
        gsap.utils.toArray(".spin-wrapper-out").forEach((elem) => {
          const currentWrapper = elem as HTMLElement;
          const triggerConfig = {
            scrollTrigger: {
              trigger: currentWrapper,
              start: "30% 5%",
              scrub: 1,
            },
          };
          gsap.set(currentWrapper, {
            opacity: 1,
            scale: 1,
            left: 0,
            rotationY: 0,
            top: 0,
          });
          gsap.to(currentWrapper, {
            left: window.innerWidth * -1 + "px",
            ease: "power4.out",
            ...triggerConfig,
          });
          gsap.to(currentWrapper, {
            rotationY: -180,
            ease: "power4",
            ...triggerConfig,
          });
          gsap.to(currentWrapper, {
            opacity: 0,
            ease: "power4.out",
            ...triggerConfig,
          });
          gsap.to(currentWrapper, {
            scale: 0,
            ease: "power1.out",
            ...triggerConfig,
          });
          gsap.to(currentWrapper, {
            top: window.innerHeight * -0.75 + "px",
            ease: CustomEase.create(
              "custom",
              "M0,0,C0,0,0.081,0.013,0.12,0.046,0.16,0.08,0.183,0.161,0.2,0.2,0.222,0.25,0.449,0.662,0.494,0.74,0.547,0.833,1,1,1,1"
            ),
            ...triggerConfig,
          });
        });

        /** TITLE ANIMATIONS */
        gsap.to("#environmental-factors .title-env", {
          scale: 0.35,
          opacity: 0.4,
          scrollTrigger: {
            trigger: "#environmental-factors .title-env",
            scrub: 1,
            start: "top 30%",
          },
        });

        gsap.to(titleFixedApp.current, {
          marginTop: -40,
          opacity: 0,
          scrollTrigger: {
            trigger: "#life-style-factors",
            scrub: 1,
            start: "top 25%",
            end: "top top",
          },
        });
      }, 500);
    }, app);

    return () => ctx.revert();
  }, []);

  return (
    <EnvironmentalFactorsWrapper ref={app} id="environmental-factors">
      <TitleFactore
        ref={titleApp}
        className="title-env"
        style={{ visibility: blockHeader ? "hidden" : "visible" }}
      >
        <b>ENVIRONMENTAL</b>
        <br />
        FACTORS
      </TitleFactore>
      <TitleFixedFactore
        ref={titleFixedApp}
        style={{ visibility: !blockHeader ? "hidden" : "visible" }}
      >
        <b>ENVIRONMENTAL</b>
        <br />
        FACTORS
      </TitleFixedFactore>
      {factorsElements.map((f, index) => (
        <div key={index}>
          <BubblePresentation item={f} />
        </div>
      ))}
      <TextPanel>
        <h2>UV PROTECTION</h2>
        <div className="description">
          <p>Netlock technology encapsulates UVA and UVB filters in a homogeneous
          film
          <br />
          <br />
          SPF 50+ and UVA PF 46, PA ++++
          <br />
          <br />
          Clinically proven to reduce dark spots by 27% in two months
          <br />
          <br />
          Formula tested under ophthalmological and dermatological control
          <br />
          <br />
          100% of volunteers say the formula doesn’t pill</p>
          <div className="img">
            <img src={NETLOCK_IMG} className="levitation" />
          </div>
          <div className="peptides">
            <p className="levitation">4% PEPTIDES</p>
          </div>
          <div className="niacinamide">
            <p className="levitation">2% NIACINAMIDE</p>
          </div>
          <div className="vitamin">
            <p className="levitation">VITAMIN E</p>
          </div>
        </div>
      </TextPanel>
      <TextPanel>
        <h2>ANTI-DARK SPOTS</h2>
        <div className="description">
          <p>Anti-pigmentation action 5% Vit B3 + TXA 1% + Peeling complex with 8%
          glycolic acid + Antioxidant vit Cg
          <br />
          <br />
          Corrects & protects from dark spots and pigmentation disorders
          <br />
          <br />
          Clinical scores on phototypes I-VI show -29% on average and up to 71%
          reduction of darks spots after 2 months
          <br />
          <br />
          78% of volunteers state skin tone evenness improved after 4 weeks</p>
          <div className="tranexamic">
            <p className="levitation">1% TRANEXAMIC ACID</p>
          </div>
          <div className="glycolic">
            <p className="levitation">8% GLYCOLIC ACID</p>
          </div>
          <div className="vitaminb">
            <p className="levitation">5% VITAMIN B3</p>
          </div>
          <div className="vitaminc">
            <p className="levitation">VITAMIN Cg</p>
          </div>
          <div className="peeling">
            <p className="levitation">PEELING ACTIVES</p>
          </div>
        </div>
      </TextPanel>
    </EnvironmentalFactorsWrapper>
  );
};

export default EnvironmentalFactors;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const EnvironmentalFactorsWrapper = styled.div`
  position: relative;
  padding-top: 50vh;
`;

const TitleFactore = styled.h2`
  padding: 0 ${MAIN_LEFT_RIGHT_MARGIN}px;
  text-align: center;
  font-size: 64px;
  margin-top: 80px;
  font-weight: 300;

  @media (max-height: 667px), (max-width: 450px) {
    font-size: 32px;
  }
`;

const TitleFixedFactore = styled(TitleFactore)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: scale(0.8);
  opacity: 0.4;
`;

const TextPanel = styled.div`
  padding: 40vh ${MAIN_LEFT_RIGHT_MARGIN}px 25vh ${MAIN_LEFT_RIGHT_MARGIN}px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2,
  div {
    text-align: center;

    &.description {
      font-size: 22px;
      position: relative;
      margin-top: 180px;
      line-height: 22px;
      max-width: 600px;
      width: 85%;

      > div {
        position: absolute;
        line-height: 32px;

        &.img {
          top: -121px;
          left: 16px;

          img {
            height: 50px;
          }

          @media (min-height: 667px) {
            top: -140px;
          }
          @media (min-width: 700px) {
            left: -16px;
          }
        }

        &.peptides {
          top: -53px;
          right: 16px;

          p {
            text-align: right;
          }

          @media (min-height: 667px) {
            top: -70px;
          }
          @media (min-width: 700px) {
            right: -16px;
          }
        }

        &.niacinamide {
          margin-top: 58px;
          top: 100%;
          right: 16px;

          p {
            text-align: right;
          }

          @media (min-width: 700px) {
            right: 0;
          }
        }

        &.vitamin {
          top: 100%;
          left: 32px;
          margin-top: 65px;

          @media (min-width: 700px) {
            left: 0;
          }
        }

        &.tranexamic {
          top: -109px;
          left: 50%;
          transform: translateX(-60%);

          @media (min-height: 667px) {
            top: -120px;
          }
        }

        &.glycolic {
          top: -66px;
          right: 16px;

          p {
            text-align: right;
          }

          @media (min-width: 700px) {
            right: -16px;
          }
        }

        &.vitaminb {
          top: -58px;
          left: 0;
        }

        &.vitaminc {
          margin-top: 44px;
          top: 100%;
          left: 8px;

          @media (min-height: 667px) {
            margin-top: 60px;
          }
          @media (min-width: 700px) {
            left: 0;
          }
        }

        &.peeling {
          top: 100%;
          margin-top: 43px;
          right: 32px;

          p {
            text-align: right;
          }

          @media (min-height: 667px) {
            margin-top: 55px;
          }
          @media (min-width: 700px) {
            right: 0;
          }
        }

        p {
          position: relative;
          opacity: 0.4;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          white-space: nowrap;
        }
      }
    }
  }
`;
