import React from "react";
import { AppProvider } from "./appContext";
import { AuthenticationProvider } from "./authenticationContext";
import { UserProvider } from "./userContext";
import { ItemsProvider } from "./itemContext";
import { PaymentProvider } from "./paymentContext";
import { PlayerProvider } from "./playerContext";
import { QuizProvider } from "./quizContext";

const Providers = (props: any) => {
  return (
    <QuizProvider>
      <PaymentProvider>
        <PlayerProvider>
          <UserProvider>
            <AppProvider>
              <ItemsProvider>
                <AuthenticationProvider {...props} />
              </ItemsProvider>
            </AppProvider>
          </UserProvider>
        </PlayerProvider>
      </PaymentProvider>
    </QuizProvider>
  );
};

export default Providers;
