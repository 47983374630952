import React, { MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import BackgroundImage from './backgrounds/BackgroundImage';
import formImage from '../../assets/images/routes/quiz/form-image.png';
import { COLOR_WHITE } from '../../constants/cts_colors';
import { quizFormQuestions } from '../../constants/cts_quiz';
import { useNavigate } from "react-router-dom";
import { getPageUrl } from '../../locales/i18n';
import PATH from '../../constants/cts_routes';
import { getQuizResult } from '../../utils/quiz';
import { v4 as uuidv4 } from 'uuid';
import { useQuiz } from '../../common/contexts/quizContext';

const Quiz = () => {
  const [formData, _setFormData] = useState(quizFormQuestions);
  const navigate = useNavigate();
  const {onRegisterQuizAnswers} = useQuiz();

  // HANDLE CHANGE
  const handleChange = (
    {selectedQuestionIndex, selectedAnswerIndex}:
    {selectedQuestionIndex: number, selectedAnswerIndex: number}
  ) => {
    const newState = formData.map((question, questionIndex) => {
      // for the selected question
      if (selectedQuestionIndex === questionIndex) {
        // for each answer of the question
        const answers: Array<{ title: string; isSelected: boolean; }> = [];
        question.answers.map((answer, answerIndex) => {
          // set the current selected answer
          if (selectedAnswerIndex === answerIndex) {
            answers.push({...answer, isSelected: true});
          }else {
            answers.push({...answer, isSelected: false});
          }
        });
        return {...question, answers: answers};
      }
      return question;
    });
    _setFormData(newState)
  }

  // HANDLE SUBMIT
  const handleSubmit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()

    const questionsNb = formData.length;

    const formAnswers: Array<{
      question: {
        title: string;
        answers: {
          title: string;
          isSelected: boolean;
        }[];
      },
      correctAnswer: string,
      selectedAnswer: string,
    }> = [];

    formData.map((question) => {
      const answer = question.answers.filter((answer) => answer.isSelected)[0]
      formAnswers.push({
        question,
        correctAnswer: question.correctAnswer,
        selectedAnswer: answer.title,
      })
    })

    let correctAnswersNb = 0;
    formAnswers.map((formAnswer) => {
      if (formAnswer.selectedAnswer === formAnswer.correctAnswer) {
        correctAnswersNb++;
      }
    })

    const quizResult = getQuizResult({correctAnswersNb, questionsNb});

    // register answers in db
    onRegisterQuizAnswers({
      userId: uuidv4(),
      formAnswers,
    })
    .then(() => {})
    .catch((error) => console.error(error))

    return navigate(`${getPageUrl(PATH.quizResult)}/${quizResult.correctAnswersNb}/${quizResult.questionsNb}`);
  }

  return (
    <Wrapper>
      <TopGradient/>
      <BackgroundImage />
      <Form>
        <img src={formImage} alt="form" />
        <div className='inputs-container'>
          {
            formData && formData.length > 0 &&
            <div className='input-container'>
              {
                formData.map((question, questionIndex) => (
                  <div key={`question-${questionIndex}`}>
                    <label className='title'>{question.title}</label>
                    {
                      question.answers && question.answers.length > 0 &&
                      question.answers.map((answer, answerIndex) => (
                        <label
                          key={`answer-${questionIndex}-${answerIndex}`}
                          htmlFor={`answer-${questionIndex}-${answerIndex}`}
                          className='answer'
                        >
                          <input 
                            type='radio'
                            id={`answer-${questionIndex}-${answerIndex}`}
                            value={answer.title}
                            checked={formData[questionIndex].answers[answerIndex].isSelected}
                            onChange={() => handleChange({selectedQuestionIndex: questionIndex, selectedAnswerIndex: answerIndex})}
                          />
                            <span className='answer-title'>{answer.title}</span>
                        </label>
                      ))
                    }
                  </div>
                ))
              }
              <button className="submit-btn" type="submit" onClick={handleSubmit}>
                SUBMIT
              </button>
            </div>
          }
        </div>
      </Form>
      <BottomGradient />
    </Wrapper>
  )
}

export default Quiz;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

const TopGradient = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4) 1%, rgba(0,0,0,0) 100%);
  z-index: 0;
`

const BottomGradient = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 99%, rgba(0,0,0,1) 100%);
  z-index: 0;
`

const Form = styled.form`
  position: absolute;
  top: 170px;
  left: 24px;
  right: 24px;
  height: calc(100% - 240px);
  overflow-y: scroll;
  padding: 0px 26px;
  z-index: 1;

  /* form scrollbar */
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255 ,255 ,255, 0.12);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255 ,255 ,255, 0.35);
    border-radius: 10px;

    &:hover {
      background: rgba(255 ,255 ,255, 0.5);
    }
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
    width: 65%;
  }

  .inputs-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-container {
      color: ${COLOR_WHITE};
      max-width: 300px;
      text-shadow: 0px 0px 20px rgba(0,0,0,0.32);
      display: flex;
      flex-direction: column;
      gap: 30px;

      .title {
        display: block;
        font-weight: bold;
        margin-left: 25px;
        margin-bottom: 10px;
      }

      .answer {
        margin-bottom: 10px;
        display: flex;
        align-items: flex-start;
        gap: 10px;

        input[type="radio"] {
          appearance: none;
          background-color: transparent;
          font: inherit;
          color: currentColor;
          min-width: 15px;
          min-height: 15px;
          border: 0.5px solid currentColor;
          border-radius: 50%;
          display: grid;
          place-content: center;
          margin-top: 2px;

          &::before {
            content: "";
            width: 7px;
            height: 7px;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em ${COLOR_WHITE};
          }

          &:checked::before {
            transform: scale(1);
          }
        }
      }

      .submit-btn {
        width: 100%;
        background-color: transparent;
        border: 1px solid ${COLOR_WHITE};
        color: ${COLOR_WHITE};
        padding: 10px 5px;
        cursor: pointer;
        border-radius: 10px;
        transition: 120ms background-color ease-in-out;

        &:hover {
          background-color: rgba(255, 255 ,255, 0.1);
        }
      }
    }
  }
`;
