import React, { createContext, useContext, useEffect, useState } from "react";
import { APP_MSG } from "../../constants/cts_contextErrors";
import { IPlayerContext } from "../../interfaces/playerContext";
import { BACKGROUND_SOUND_AMBIENCE } from "../../constants/cts_eternal_assets";

const PlayerContext = createContext(null)
const BACKGROUND_AUDIO = new Audio(BACKGROUND_SOUND_AMBIENCE);
BACKGROUND_AUDIO.loop = true;
BACKGROUND_AUDIO.volume = 0.5;

export const PlayerProvider = (props : any) => {
  const [ isPlay, setIsPlay ] = useState(false);

  const togglePlay = () => {
    setIsPlay(!isPlay)
  }

  useEffect(() => {
    console.log('isPlay', isPlay)
    if (isPlay) {
      BACKGROUND_AUDIO.play();
    } else {
      BACKGROUND_AUDIO.pause();
    }
  }, [isPlay]);

  return (
    <PlayerContext.Provider 
      {...props}
      value={{
        isPlay,
        // function
        setIsPlay,
        togglePlay,
      }}
    /> 
  )
}

export const usePlayer = (): IPlayerContext => {
  const context = useContext(PlayerContext)
  if (!context)
    throw new Error(APP_MSG);
  return context;
}
