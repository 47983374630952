import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MAIN_LEFT_RIGHT_MARGIN } from "../../../constants/cts_styles";
import { gsap } from "gsap";
import BubblePresentation, {
  BubblePresentationInterface,
} from "../components/BubblePresentation";
import { useApp } from "../../../common/contexts/appContext";

const HormonalFactors = () => {
  const app = useRef<HTMLDivElement>(null);
  const titleApp = useRef<HTMLDivElement>(null);
  const titleFixedApp = useRef<HTMLDivElement>(null);
  const [blockHeader, setBlockHeader] = useState(false);
  const { y } = useApp();
  const factorsElements: BubblePresentationInterface[] = [
    {
      key: "3-1",
      iconPath: "/images/circles/active-period.svg",
      circle: "top-white",
      bottom: {
        htmlDescription:
          "Hormonal imbalances are often accompanied by an increase of androgens leading to acne.",
      },
    },
    {
      key: "3-2",
      iconPath: "/images/circles/post-partum.svg",
      circle: "top-white",
      bottom: {
        htmlDescription:
          "Many women suffer from hyperpigmentation disorders, this is also related to the fact that during third trimester of pregnancy there is an increase of estrogen, progeste and melanocyte stimulating hormone levels.",
      },
    },
    {
      key: "3-3",
      iconPath: "/images/circles/menopause.svg",
      circle: "top-white",
      bottom: {
        htmlDescription:
          "Estrogen decreases and cortisol increases leading to thinning skin, sebum irregularities, wrinkles, sagging, dullness, and more fragile, sensitive skin.",
      },
    },
  ];

  const factorsElementsSuite: BubblePresentationInterface[] = [
    {
      key: "4-1",
      iconPath: "/images/circles/active-period.svg",
      circle: "top-white",
      bottom: {
        htmlDescription:
          "<b>61</b>% of women report a presence or worsening of skin disorders during certain times of the cycle.",
        showLine: true,
      },
    },
    {
      key: "4-2",
      iconPath: "/images/circles/post-partum.svg",
      circle: "top-white",
      bottom: {
        htmlDescription:
          "Post-partum women <b>93</b>% of them have at least one skin disorder during that period.",
        showLine: true,
      },
    },
    {
      key: "4-3",
      iconPath: "/images/circles/menopause.svg",
      circle: "top-white",
      bottom: {
        htmlDescription:
          "<b>72</b>% notice changes on their skin,<br/>and half of them feel insufficiently informed.",
        showLine: true,
      },
    },
    {
      key: "4-4",
      iconPath: "/images/circles/peri-menopause.svg",
      circle: "top-white",
      bottom: {
        htmlDescription: "<b>2x more</b> hair and scalp problems.",
        showLine: true,
      },
    },
  ];

  useEffect(() => {
    if (titleApp) {
      // @ts-ignore
      // const { top } = titleApp.current?.getBoundingClientRect();
      // setBlockHeader(top < 100);
    }
  }, [y]);

  useEffect(() => {
    let ctx = gsap.context(() => {
      setTimeout(() => {
        gsap.to(titleApp.current, {
          scale: 0.75,
          opacity: 0.4,
          scrollTrigger: {
            trigger: titleApp.current,
            scrub: 1,
            start: "top 30%",
          },
        });

        gsap.to(titleFixedApp.current, {
          marginTop: -40,
          opacity: 0,
          scrollTrigger: {
            trigger: "#end-panel",
            scrub: 1,
            start: "top 25%",
            end: "top top",
          },
        });
      }, 500);
    }, app);

    return () => ctx.revert();
  }, []);

  return (
    <LifeStyleFactorsWrapper ref={app} id="hormonal-factors">
      <TitleFactore className="title-env">
        <div>
          <h2
            ref={titleApp}
            style={{ visibility: blockHeader ? "hidden" : "visible" }}
          >
            <b>HORMONAL</b>
            <br />
            FACTORS
          </h2>
          <div className="active levitation-block">
            <p className="levitation">ACTIVE PERIOD</p>
          </div>
          <div className="perimenopause levitation-block">
            <p className="levitation">PERIMENOPAUSE</p>
          </div>
          <div className="post-partum levitation-block">
            <p className="levitation">POST-PARTUM</p>
          </div>
          <div className="menopause levitation-block">
            <p className="levitation">MENOPAUSE</p>
          </div>
        </div>
      </TitleFactore>
      <TitleFixedFactore
        ref={titleFixedApp}
        style={{ visibility: !blockHeader ? "hidden" : "visible" }}
      >
        <b>HORMONAL</b>
        <br />
        FACTORS
      </TitleFixedFactore>
      <TextPanel>
        <div>
          <p className="description spaced">
            Skin itself is an endocrine organ
            <br />
            Epidermal keratinocytes express sex hormone receptors such as
            estrogen, progesterone, and androgen.
          </p>
          <div className="physical">
            <p className="levitation">
              PHYSICAL
              <br />
              APPEARANCE
            </p>
          </div>
          <div className="menstrual">
            <p className="levitation">
              MENSTRUAL
              <br />
              CYCLE
            </p>
          </div>
          <div className="skin">
            <p className="levitation">
              SKIN AND
              <br />
              SCALP HEALTH
            </p>
          </div>
        </div>
      </TextPanel>
      {factorsElements.map((f, index) => (
        <div key={index}>
          <BubblePresentation item={f} />
        </div>
      ))}
      <TextPanel>
        <div>
          <p className="description pressive-elements">
            <b>
              EPIDEMIOLOGICAL STUDY ON HORMONES AND THE IMPACT ON SKIN, SCALP &
              WELL-BEING
            </b>
            <br />
            <br />
            <span>
              IN <b>20000</b> WOMEN
            </span>
            <br />
            <br />
            <span>
              FROM <b>20</b> COUNTRIES
            </span>
            <br />
            <br />
            <span>
              OF ALL <b>PHOTOTYPES</b>
            </span>
            <br />
            <br />
            <span>
              AT DIFFERENT
              <br />
              <b>STAGES OF LIFE</b>.
            </span>
          </p>
        </div>
      </TextPanel>
      {factorsElementsSuite.map((f, index) => (
        <div key={index}>
          <BubblePresentation item={f} />
        </div>
      ))}
      <TextEndPanel>
        <h2>
          MENOPAUSAL
          <br />
          SKINCARE
        </h2>
        <div>
          <div className="proxylane">
            <p className="levitation">PROXYLANE</p>
          </div>
          <div className="cassia">
            <p className="levitation">CASSIA EXTRACT</p>
          </div>
          <p className="description">
            Compensates for the visible skin ageing signs linked to hormonal
            variations by reactivating skin mechanisms at menopause
            <br />
            <br />
            Powered by Proxylane and Cassia Extract:
            <br />a duo with in vitro proven efficacy to multiply biosynthesis
            of extracellular matrix by 10 times
            <br />
            <br />
            Epidermal lipids replenish in 2 weeks.
            <br />
            <br />
            <b>-25</b>% Sagginess. <b>-28</b>% Wrinkles. <b>-50</b>% Dryness.
            <br />
            *Clinical scoring, 220 women after 3 months
          </p>
          <div className="omegas">
            <p className="levitation">OMEGAS 3-6-9</p>
          </div>
          <div className="vichy">
            <p className="levitation">
              VICHY
              <br />
              VOLCANIC WATER
            </p>
          </div>
        </div>
      </TextEndPanel>
      <TextEndPanel>
        <h2>
          MELASMA
          <br />
          CORRECTION
        </h2>
        <div>
          <div className="tranexamic">
            <p className="levitation">1% TRANEXAMIC ACID</p>
          </div>
          <div className="vitaminb">
            <p className="levitation">5% VITAMIN B3</p>
          </div>
          <div className="glycolic">
            <p className="levitation">8% GLYCOLIC ACID</p>
          </div>
          <p className="description">
            <b>5</b>% Vitamin B3 + <b>1</b>% tranexamic acid + <b>8</b>%
            glycolic acid + peeling actives Vitamin Cg
            <br />
            <br />
            5-month clinical study on melasma VS hydroquinone <b>4</b>% showing
            similar efficacy and better tolerance
            <br />
            <br />
            mMasi (modified Melasma Area and Severity Index) score <b>38</b>%
            decrease after 5 months <br />
            <br />
            <b>85</b>% overall global tolerance after 3 months
          </p>
          <div className="vitaminc">
            <p className="levitation">VITAMIN Cg</p>
          </div>
          <div className="peeling">
            <p className="levitation">PEELING ACTIVES</p>
          </div>
        </div>
      </TextEndPanel>
    </LifeStyleFactorsWrapper>
  );
};

export default HormonalFactors;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const LifeStyleFactorsWrapper = styled.div`
  position: relative;
`;

const TitleFactore = styled.div`
  padding: 0 ${MAIN_LEFT_RIGHT_MARGIN}px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    position: relative;
  }

  h2 {
    text-align: center;
    font-size: 64px;
    font-weight: 300;

    @media (max-height: 667px), (max-width: 450px) {
      font-size: 32px;
    }

    @media (min-width: 700px) {
      width: 400px;
    }
  }

  .levitation-block {
    font-size: 32px;
    position: absolute;
    color: white;
    font-weight: bolder;
    text-align: center;
    opacity: 0.4;

    > p {
      position: relative;
      white-space: nowrap;
    }

    &.active {
      right: -64px;
      top: -180px;
    }

    &.perimenopause {
      left: -64px;
      top: -100px;
    }

    &.post-partum {
      left: -64px;
      bottom: -100px;
    }

    &.menopause {
      right: -64px;
      bottom: -160px;
    }
  }
`;

const TitleFixedFactore = styled.h2`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: scale(0.8);
  opacity: 0.4;
  text-align: center;
  font-size: 32px;
  margin-top: 90px;
  font-weight: 300;
`;

const TextPanel = styled.div`
  padding: 10vh ${MAIN_LEFT_RIGHT_MARGIN}px 40vh ${MAIN_LEFT_RIGHT_MARGIN}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25vh;

  > div {
    position: relative;
  }

  p {
    text-align: center;

    &.description {
      font-size: 24px;
      max-width: 400px;
      width: 85%;
      margin: auto;
    }

    &.spaced {
      line-height: 24px;
    }
  }

  .physical,
  .menstrual,
  .skin {
    position: absolute;
    line-height: 32px;

    &.physical {
      top: -130px;
      left: 40%;
      transform: translateX(-50%);
    }

    &.menstrual {
      right: 16px;
      bottom: -100px;

      @media (min-width: 700px) {
        right: -64px;
      }
    }

    &.skin {
      left: 8px;
      bottom: -170px;

      @media (min-width: 700px) {
        left: -32px;
      }
    }

    p {
      position: relative;
      opacity: 0.4;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      white-space: nowrap;
    }
  }
`;

const TextEndPanel = styled.div`
  padding: 20vh ${MAIN_LEFT_RIGHT_MARGIN}px 40vh ${MAIN_LEFT_RIGHT_MARGIN}px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2,
  p {
    text-align: center;

    &.description {
      font-size: 22px;
      line-height: 22px;
      max-width: 600px;
      width: 85%;
      margin: auto;

      span {
        display: block;
      }
    }
  }

  > div {
    position: relative;
    margin-top: 129px;
  }

  .proxylane,
  .cassia,
  .omegas,
  .vichy,
  .tranexamic,
  .glycolic,
  .vitaminb,
  .vitaminc,
  .peeling {
    position: absolute;

    &.proxylane {
      left: 8px;
      top: -85px;
    }

    &.cassia {
      top: -63px;
      right: 32px;

      @media (min-height: 667px) {
        top: -70px;
      }
      @media (min-width: 700px) {
        right: 32px;
      }
    }

    &.omegas {
      top: 100%;
      margin-top: 38px;
      right: 32px;

      p {
        text-align: right;
      }

      @media (min-height: 667px) {
        margin-top: 50px;
      }
      @media (min-width: 700px) {
        right: 32px;
      }
    }

    &.vichy {
      top: 100%;
      left: 32px;
      margin-top: 60px;

      p {
        text-align: center;
      }

      @media (min-height: 667px) {
        margin-top: 50px;
      }
      @media (min-width: 700px) {
        left: 16px;
      }
    }

    &.tranexamic {
      top: -115px;
      right: 20%;

      p {
        text-align: right;
      }
    }

    &.vitaminb {
      top: -70px;
      left: 5%;

      @media (min-width: 700px) {
        left: -5%;
      }
    }

    &.glycolic {
      top: -50px;
      right: 5%;

      p {
        text-align: right;
      }

      @media (min-width: 700px) {
        right: -5%;
      }
    }

    &.vitaminc {
      top: 100%;
      margin-top: 44px;
      left: 10%;

      @media (min-height: 667px) {
        margin-top: 55px;
      }
      @media (min-width: 700px) {
        left: 5%;
      }
    }

    &.peeling {
      top: 100%;
      margin-top: 34px;
      right: 10%;

      p {
        text-align: right;
      }

      @media (min-height: 667px) {
        margin-top: 64px;
      }
      @media (min-width: 700px) {
        right: 5%;
      }
    }

    p {
      position: relative;
      opacity: 0.4;
      font-size: 16px;
      font-weight: bold;
      text-align: left;
    }
  }
`;
