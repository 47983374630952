import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { MAIN_LEFT_RIGHT_MARGIN } from "../../../constants/cts_styles";
import Button from "../../../components/Button";
import { getPageUrl } from "../../../locales/i18n";
import PATH from "../../../constants/cts_routes";

const EndPanel = () => {
  return (
    <LifeStyleFactorsWrapper id="end-panel">
      <TitleFactore><h2>AND NOW<br/><b>TEST YOUR KNOWLEDGE</b></h2>
      <Button to={getPageUrl(PATH.quiz)}>START NOW</Button></TitleFactore>
    </LifeStyleFactorsWrapper>
  );
};

export default EndPanel;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const LifeStyleFactorsWrapper = styled.div`
`;

const TitleFactore = styled.div`
  height: 100vh;
  padding: 0 ${MAIN_LEFT_RIGHT_MARGIN}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    text-align: center;
    font-size: 32px;
    font-weight: 300;
    max-width: 300px;
    margin-bottom: 66px;
  }
`;
