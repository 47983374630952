import React, { useEffect, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import HomeCover from "./panels/HomeCover";
import BackgroundVideo from "./backgrounds/BackgroundVideo";
import HomeLogo from "./panels/HomeLogo";
import EnvironmentalFactors from "./panels/EnvironmentalFactors";
import LifeStyleFactors from "./panels/LifeStyleFactors";
import EndPanel from "./panels/EndPanel";
import HormonalFactors from "./panels/HormonalFactors";
import BottomActions from "./components/BottomActions";
import { gsap } from "gsap";
import { getRandomInt } from "../../utils/utils";

const LARGE_VALUE = 60;
const MEDIUM_VALUE = 40;

const HomePage = () => {
  const app = useRef<HTMLDivElement>(null);

  /** Reload on resize */
  var lastWidth = document.body.clientWidth;
  window.onresize = function () {
    if (document.body.clientWidth != lastWidth) {
      lastWidth = document.body.clientWidth;
      location.reload();
    }
  };

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      setTimeout(() => {
        const leviationElements = document.getElementsByClassName("levitation");
        Array.from(leviationElements).forEach((e) => {
          const tlCan = gsap.timeline({
            repeat: -1,
            defaults: {
              duration: 3,
            },
          });

          let yTop = getRandomInt(LARGE_VALUE);
          if (yTop < LARGE_VALUE / 3) {
            yTop = LARGE_VALUE / 3;
          }
          let xTop = getRandomInt(MEDIUM_VALUE);
          if (xTop < MEDIUM_VALUE / 3) {
            xTop = MEDIUM_VALUE / 3;
          }

          tlCan.to(e, {
            y: (yTop > LARGE_VALUE / 2 ? "+" : "-") + "=" + yTop / 2,
            x: (xTop > MEDIUM_VALUE / 2 ? "+" : "-") + "=" + xTop / 2,
          });
          tlCan.to(e, {
            y: (yTop <= LARGE_VALUE / 2 ? "+" : "-") + "=" + yTop / 2,
            x: (xTop <= MEDIUM_VALUE / 2 ? "+" : "-") + "=" + xTop / 2,
          });
        });

        const descriptionsElements =
          document.getElementsByClassName("pressive-elements");
        Array.from(descriptionsElements).forEach((e) => {
          const elements = e.getElementsByTagName("span");
          Array.from(elements).forEach((element) => {
            gsap.fromTo(
              element,
              { opacity: 0 },
              {
                opacity: 1,
                scrollTrigger: {
                  trigger: element,
                  start: "top 75%",
                  end: "bottom 40%",
                  scrub: 1,
                },
              }
            );
          });
        });
      }, 1000);
    }, app);

    return () => ctx.revert();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper ref={app}>
      <BackgroundVideo />
      <HomeCover />
      <HomeLogo />
      <OneFactore>
        <EnvironmentalFactors />
        <LifeStyleFactors />
        <HormonalFactors />
      </OneFactore>
      <EndPanel />

      <BottomActions />
    </Wrapper>
  );
};

export default HomePage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  color: white;
  overflow: hidden;
`;

const OneFactore = styled.div``;
