import { IHeaderMenuLinks } from "../interfaces/headerMenu";
import PATH from "./cts_routes";

export const headerMenuLinks : IHeaderMenuLinks[] = [ 
  {
    name : "Home",
    to : PATH.home,
  },
  {
    name : "Quiz",
    to : PATH.quiz,
  },
  {
    name: "Legal Mentions",
    to: PATH.legalMentions,
  },
];