import React, { useCallback, useEffect } from 'react';
import AppRoutes from './routes/routes';
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useApp } from './common/contexts/appContext';
import { CustomEase } from 'gsap/all';
import { CSSRulePlugin } from 'gsap/all';
import {Helmet} from "react-helmet";
gsap.registerPlugin(ScrollTrigger, CustomEase, CSSRulePlugin);

function App() {
  const { y, setY } = useApp()

  const handleNavigation = useCallback(() => {
    setY(window.scrollY);
  }, [y]);

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <Wrapper>
      <Helmet>
        <title>Exposome</title>
        <meta property="og:title" content="Exposome" />
        <meta property="og:description" content="Welcome to the immersive exposome experience" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_ORIGIN_URL}`} />
        <meta property="og:image" content={`${process.env.REACT_APP_ORIGIN_URL}/images/logo-black.png`} />
      </Helmet>
      <AppRoutes />
    </Wrapper>
  );
}

export default App;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width:  100vw;
`