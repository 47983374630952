import React, { useEffect } from "react";
import { Routes, Route, Navigate, useParams, Outlet } from "react-router-dom";
import WrapperConnected from "../components/wrapper-conected";
import HomePage from "./homePage/homePage";
import LegalMentions from "./legalMentions/legalMentions";
import Contact from "./contact/contact";
import PATH from "../constants/cts_routes";
import { I18nextProvider } from "react-i18next";
import i18n from "../locales/i18n";
import { DEFAULT_LANGUAGE } from "../constants/cts_language";
import { useApp } from "../common/contexts/appContext";
import { languages } from "../constants/cts_languages";
import Quiz from "./quiz/quiz";
import QuizResult from "./quiz/quizResult";

const AppRoutes = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Routes>
        <Route path="/" element={
          <RedirectLanguagePage />
        } />
        <Route path="/:lang" element={<LanguagePage />}>
          <Route
            index
            element={
              <WrapperConnected>
                <HomePage />
              </WrapperConnected>
            }
          />
          <Route
            path={PATH.legalMentions}
            element={
              <WrapperConnected>
                <LegalMentions />
              </WrapperConnected>
            }
          />
          <Route
            path={PATH.contact}
            element={
              <WrapperConnected>
                <Contact />
              </WrapperConnected>
            }
          />
          <Route
            path={PATH.quiz}
            element={
              <WrapperConnected>
                <Quiz />
              </WrapperConnected>
            }
          />
          <Route
            path={`${PATH.quizResult}/:correctAnswersNb/:questionsNb`}
            element={
              <WrapperConnected>
                <QuizResult />
              </WrapperConnected>
            }
          />
        </Route>
        <Route path="/*" element={<RedirectToIndexLanguagePage />}/>
      </Routes>
    </I18nextProvider>
  );
};

const LanguagePage: React.FC = (): JSX.Element => {
  const { lang } = useParams();
  const { onSendLanguage } = useApp();

  useEffect(() => {
    // send selected language to the server
    // the language will be changed on api
    //onSendLanguage(lang || DEFAULT_LANGUAGE)
    //  .then(() => i18n.changeLanguage(lang));
  }, [lang]);

  if(!languages.find(l => l.code === lang)) {
    const urlRedirected = location.pathname.replace('/'+lang, '')
    return <Navigate to={`/${DEFAULT_LANGUAGE}${urlRedirected}`} />;
  }

  return <Outlet />;
};

const RedirectToIndexLanguagePage = () => {
  let navigatorLangageDetected = navigator.language
    ? navigator.language
    : DEFAULT_LANGUAGE;
  // remove - to language string
  navigatorLangageDetected = navigatorLangageDetected.split("-")[0];
  return <Navigate to={`/${navigatorLangageDetected}/${PATH.home}`} />;
}

class RedirectLanguagePage extends React.Component {
  render() {
    let navigatorLangageDetected = /*navigator.language
      ? navigator.language
      : */DEFAULT_LANGUAGE;
      // TODO FORCE FR

    // remove - to language string
    navigatorLangageDetected = navigatorLangageDetected.split("-")[0];

    return <Navigate to={`/${navigatorLangageDetected}`} />;
  }
}


export default AppRoutes;
