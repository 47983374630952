import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { COLOR_WHITE } from "../../constants/cts_colors";
import BackgroundImage from "./backgrounds/BackgroundImage";
import { getPageUrl } from "../../locales/i18n";
import PATH from "../../constants/cts_routes";
import { useNavigate } from "react-router-dom";
import vLogo from "../../assets/images/routes/quiz/v-logo.png";
import { getQuizResult } from "../../utils/quiz";
import { IQuizResult } from "../../interfaces/quiz";
import { Helmet } from "react-helmet";
import facebookShareIcon from "../../assets/images/routes/quiz/facebook-share-button-icon.svg";
import linkedinShareIcon from "../../assets/images/routes/quiz/linkedin-share-button-icon.svg";
import shareIcon from "../../assets/images/routes/quiz/share-button-icon.svg";

const QuizResult = () => {
  const navigate = useNavigate();
  const { correctAnswersNb, questionsNb } = useParams();
  const [result, _setResult] = useState<IQuizResult>();

  // SET THE RESULT
  useEffect(() => {
    if (correctAnswersNb && questionsNb) {
      _setResult(
        getQuizResult({
          correctAnswersNb: parseInt(correctAnswersNb),
          questionsNb: parseInt(questionsNb),
        })
      );
    } else {
      return navigate(getPageUrl(PATH.quiz));
    }
  }, [correctAnswersNb, questionsNb]);

  const onShare = () => {
    const shareData = {
      title: "Exposome",
      text: `Exposome | The result of the quiz ${
        result && `${result.correctAnswersNb}/${result.questionsNb} !`
      } | Take a shot yourself`,
      url: `${process.env.REACT_APP_ORIGIN_URL}/en/quiz`,
    };
    navigator.share(shareData);
  };

  return (
    <Wrapper>
      {/* Head for the page */}
      <Helmet>
        <title>{`Exposome | The result of the quiz ${
          result && `${result.correctAnswersNb}/${result.questionsNb} !`
        } | Take a shot yourself`}</title>
        <meta
          property="og:title"
          content={`Exposome | The result of the quiz ${
            result ? `${result.correctAnswersNb}/${result.questionsNb} !` : ""
          } | Take a shot yourself`}
        />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_ORIGIN_URL}/en/quiz`}
        />
      </Helmet>
      {/* The page */}
      <BackgroundImage />
      {result && (
        <Content>
          <div className="result-container">
            <h1 className="title">
              Your result is: <br />
              <span>
                {result.correctAnswersNb}/{result.questionsNb}
              </span>
            </h1>
            <img className="logo" src={vLogo} alt="logo" />
            {result.response && (
              <span className="result-text">{result.response.text}</span>
            )}
            {process.env.REACT_APP_ORIGIN_URL && (
              <div className="share-container">
                {/*<Link
                  to={`https://www.facebook.com/sharer/sharer.php?u=${`${encodeURI(
                    process.env.REACT_APP_ORIGIN_URL
                  )}${getPageUrl(
                    PATH.quizResult
                  )}/${correctAnswersNb}/${questionsNb}`}`}
                  className="share-link"
                  target="_blank"
                >
                  <img
                    className="share-icon"
                    src={facebookShareIcon}
                    alt="facebook share"
                  />
                  </Link>*/}
                <Link
                  to={`https://www.linkedin.com/shareArticle?mini=true&url=${`${encodeURI(
                    process.env.REACT_APP_ORIGIN_URL
                  )}${getPageUrl(
                    PATH.quizResult
                  )}/${correctAnswersNb}/${questionsNb}`}`}
                  className="share-link"
                  target="_blank"
                >
                  <img
                    className="share-icon"
                    src={linkedinShareIcon}
                    alt="linkedin share"
                  />
                </Link>
                {/* @ts-ignore */}
                {navigator && navigator.share && (
                  <div
                    onTouchStart={onShare}
                    onClick={onShare}
                    className="share-link"
                  >
                    <img className="share-icon" src={shareIcon} alt="share" />
                  </div>
                )}
              </div>
            )}
          </div>
        </Content>
      )}
    </Wrapper>
  );
};

export default QuizResult;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  padding: 0px 26px;

  .result-container {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;

    .title {
      position: absolute;
      top: 10px;
      width: 100%;
      color: ${COLOR_WHITE};
      font-weight: 900;
      font-size: 2.5rem;
      text-align: center;

      span {
        font-size: 3rem;
      }

      @media (max-width: 700px) {
        font-size: 6.5vw;
      }
    }

    .logo {
      width: 500px;
      display: block;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 700px) {
        width: 100%;
      }
    }

    .result-text {
      position: absolute;
      top: 30%;
      color: ${COLOR_WHITE};
      text-align: center;
      font-size: 2.5rem;
      font-weight: 500;

      @media (max-width: 700px) {
        font-size: 6.5vw;
      }
    }

    .share-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;

      .share-link {
        color: ${COLOR_WHITE};
        opacity: 0.8;
        transition: 300ms opacity ease-out;

        &:hover {
          opacity: 1;
        }

        .share-icon {
          display: block;
          width: 100px;
        }
      }
    }
  }
`;
